export default class DeptTableItem {
    deptId = 0;
    deptStatus = 0;
    orgTypeId = 0;
    orgTypeIdName = "";
    deptParentId = 0;
    deptParentName = "";
    deptSortId = 0;
    deptName = "";
    deptRemark = "";
    createName = "";
    createTime = "";
    modifyName = "";
    modifyTime = "";
}
