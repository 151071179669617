import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import SelectLevel from "@/modules/select-level/select-level.vue";
import SelectDept from "@/modules/select-dept/select-dept.vue";
import DraggableListview from "@/modules/draggable-listview/draggable-listview.vue";
import DeptTableItem from "@/entity/system/master/DeptTableItem";
import API from "@/api/system/master/dept";
import OrgTypeAPI from "@/api/system/master/org-type";

@Options({
    name: "app-system-master-dept",
    components: {
        "app-select-level": SelectLevel,
        "app-select-dept": SelectDept,
        "app-draggable-listview": DraggableListview,
    },
})
export default class Dept extends BaseTableMixins<DeptTableItem> implements Partial<BaseTableInterface<DeptTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.tableRequestItem.lockItem = this.getLockItem();
        this.editTableItem = new DeptTableItem();
        this.editModalItem = new DeptTableItem();
    }

    //锁定列数据实体
    public getLockItem(): any {
        return {
            orgTypeId: {
                type: "eq",
                name: "org_type_id",
                title: "组织类型",
                parent: null,
                value: null,
                openFn: this.orgTypeIdLevelModalOpen,
            },
            deptParentId: {
                type: "router",
                name: "dept_id",
                title: "上级部门",
                parent: "O" + this.authUser.orgCode + "D-1",
                value: null,
                openFn: this.deptParentIdLevelModalOpen,
            },
        };
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //组织类型API
    get getOrgTypeAPI(): any {
        return OrgTypeAPI;
    }

    // //列表回调
    // public listCallback(items: Array<DeptTableItem>): void {
    //     return;
    // }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new DeptTableItem();
        this.editModalItem = new DeptTableItem();
        this.editModalItem.deptStatus = 1;
        this.editModalTitle = "添加部门";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.deptName)) return "部门名称不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.deptStatus)) return "部门状态不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.deptParentId)) return "上级部门不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.orgTypeId)) return "组织类型不能为空！";
        return null;
    }
    // //添加回调
    // public addModalCallback(res: any): void {
    //     return;
    // }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改部门 (" + this.editModalItem.deptName + ")";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.deptId)) return "部门ID不能为空！";
        return this.addSubmitChecking();
    }
    // //修改回调
    // public modModalCallback(res: any): void {
    //     return;
    // }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除部门",
            message: "部门名称: " + this.editModalItem.deptName + "<br>请确认删除？",
        };
    }
    // //删除提示框配置
    // public delAlertSetting(): void {
    //     return;
    // }
    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.deptId)) return "部门ID不能为空！";
        return null;
    }
    // //删除回调
    // public delAlertCallback(res: any): void {
    //     return;
    // }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "部门状态",
            message: "功能名称: " + this.editModalItem.deptName + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.deptStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.deptStatus = this.editModalItem.deptStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.deptId)) return "部门ID不能为空！";
        return null;
    }
    // //状态切换回调
    // public toggleStatusCallback(): void {
    //     return;
    // }

    //排序弹窗配置
    public sortModalSetting(): void {
        this.editSortBaseTitle = "部门排序";
    }
    //排序提交检测
    public sortSubmitChecking(): string {
        if (this.editModalItem.deptParentId < -1) {
            this.toast.error("上级ID不能小余-1");
            return;
        }
        return null;
    }

    //orgTypeId搜索字段
    private orgTypeIdLevelSearchField: any = [
        { field: "org_type_name", name: "名称" },
        { field: "org_type_remark", name: "说明" },
    ];
    //orgTypeId层级选择器打开
    public orgTypeIdLevelModalOpen() {
        (this.$refs.orgTypeIdLevel as any).openModal();
    }
    //orgTypeId层级选择器提交
    public async orgTypeIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().orgTypeId : item;
        this.tableRequestItem.lockItem.orgTypeId.value = curItem.value;
        this.tableRequestItem.lockItem.orgTypeId.title = curItem.title;
        this.tableRequestItem.lockItem.orgTypeId.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //deptParentId搜索字段
    private deptParentIdLevelSearchField: any = [
        { field: "dept_name", name: "名称" },
        { field: "dept_remark", name: "说明" },
    ];
    //deptParentId层级选择器打开
    public deptParentIdLevelModalOpen() {
        (this.$refs.deptParentIdLevel as any).openModal();
    }
    //deptParentId层级选择器提交
    public async deptParentIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().deptParentId : item;
        this.tableRequestItem.lockItem.deptParentId.value = curItem.value;
        this.tableRequestItem.lockItem.deptParentId.title = curItem.title;
        this.tableRequestItem.lockItem.deptParentId.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    get deptParentIdSelectApi() {
        return {
            api: (query: any) => API.idSelect(query),
            parent: "dept_parent_id",
            status: "dept_status",
            name: "dept_name",
            id: "dept_id",
            cid: this.editModalItem.deptParentId,
            mid: this.editModalItem.deptId,
            extend: true,
        };
    }

    get orgTypeIdSelectApi() {
        return {
            api: (query: any) => OrgTypeAPI.idSelect(query),
            status: "org_type_status",
            name: "org_type_name",
            id: "org_type_id",
            cid: this.editModalItem.orgTypeId,
            extend: false,
        };
    }
}
